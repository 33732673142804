<template>
  <el-dialog
    :title="title"
    :visible="visible"
    width="670px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>
      <p class="subtitle mb-[20px] text-[#636363]">{{ subtitle }}</p>
      <BaseElForm v-if="showSpecialTime" label-position="left" label-width="79px">
        <BaseElFormItem v-for="item in weeksOptions" :key="item.value" :label="item.label">
          <p v-if="formatTimeRange(item.value).length == 0">休息日</p>
          <template v-else>
            <p v-for="(item,index) in formatTimeRange(item.value)" :key="index">{{ item.timeStart }} ~ {{ item.timeEnd }} </p>
          </template>
        </BaseElFormItem>
      </BaseElForm>
      <div v-else class="text-[#5E5E5E] text-[14px]">24 小時營業</div>
    </div>

    <slot name="btnContainer">
      <div class="flex justify-end items-center">
        <BaseElButton type="primary" @click="$emit('close')">
          {{ btn || '取消' }}
        </BaseElButton>
      </div>
    </slot>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import { GetShopBusinessTimeConfig } from '@/api/shopBusinessTime'
import { isArray, get } from 'lodash'
import store from '@/store'
import { weeksOptions, businessTimeOptions } from '@/config/date'
export default defineComponent({
  name: 'ShopBusinessTimeDialog',
  props: {
    visible: { type: Boolean },
    title: { type: String },
    subtitle: { type: String },
    btn: { type: String, default: '' },
  },
  setup (props) {
    const loading = ref(false)
    const formData = reactive({
      mode: null,
      customWeekConfigs: [],
    })
    const shopId = computed(() => store.getters.shop)
    const showSpecialTime = computed(() => formData.mode === 'CustomWeek')
    const getShopBusinessTimeConfig = async () => {
      loading.value = true
      const [res, err] = await GetShopBusinessTimeConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      formData.mode = get(res, 'mode')

      const customWeekConfigs = get(res, 'customWeekConfigs')
      const newWeeks = customWeekConfigs.map(item => {
        const { week, timeStart, timeEnd } = item
        return {
          week: week,
          timeStart: minutesToTime(timeStart),
          timeEnd: minutesToTime(timeEnd),
        }
      })
      formData.customWeekConfigs = newWeeks || [{ week: null, timeStart: null, timeEnd: null }]
    }
    const formatTimeRange = (value) => {
      const customWeekConfigs = get(formData, 'customWeekConfigs')
      const newArray = customWeekConfigs.filter(item => {
        return item.week === value
      })
      return newArray.sort()
    }
    const minutesToTime = (minutes) => {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60

      const formattedHours = hours.toString().padStart(2, '0')
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0')

      return `${formattedHours}:${formattedMinutes}`
    }
    onMounted(() => {
      getShopBusinessTimeConfig()
    })
    return { formData, showSpecialTime, weeksOptions, formatTimeRange }
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  @apply pt-[10px]
}
::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
  color: var(--info);
  line-height: 20.27px;
}
</style>
